<template>
  <footer
    v-if="footer"
    class="footer"
    :style="footerStyle"
  >
    <div class="container">
      <FooterTop>
        <div class="footer-nav">
          <SbFooterItems :blok="footer.story.content" />
        </div>
      </FooterTop>
      <FooterMiddle />
      <FooterBottom />
    </div>
  </footer>
</template>

<script lang="ts" setup>
interface Props {
  footerColor: string
}
const props = defineProps<Props>()

const storyblokApiInstance = useStoryblokApi()
const mainStore = useMainStore()
const { footer } = storeToRefs(mainStore)
const { locale } = useI18n()

const config = useRuntimeConfig()

const { data } = await useAsyncData(
  `footer-${locale.value}`,
  async () =>
    await storyblokApiInstance.get(`cdn/stories/global/newfooter`, {
      language: locale.value,
      version: config.public.storyblokApiVersion as 'draft' | 'published',
    }),
)
footer.value = data.value?.data

const footerStyle = computed(() => {
  return {
    'background-color': props.footerColor
  }
})

</script>

<style lang="scss" scoped>
.footer {
  padding: 80px 0;
  font-size: var(--font-size-paragraph-s);
  letter-spacing: var(--letter-spacing-paragraph-s);

  @media (min-width: $breakpoint-md) {
    padding: 56px 0;
  }

  @media (min-width: $breakpoint-lg) {
    padding: 64px 0 80px;
  }
}

.container {
  @media (min-width: $breakpoint-md) {
    --container-padding: 54px;
  }

  @media (min-width: $breakpoint-lg) {
    --container-padding: 20px;
  }
}

.footer-nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media (min-width: $breakpoint-md) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (min-width: $breakpoint-lg) {
    justify-content: flex-start;
  }

  &__text {
    margin-right: 8px;
  }
}
</style>
